import { Typography, Button } from "@mui/material"
import { FC } from "react"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { useSwitchDivision } from "../../../hooks/useSwitchDivision"

export const DefaultDivisionWidget: FC = () => {
  const { isLoading, handleSwitchDivisions } = useSwitchDivision()
  const { assignedDivisionId, assignedDivision, division } = useCurrentUser()

  const divisionName = division?.name ?? ""
  const assignedDivisionName = assignedDivision?.name ?? ""

  return (
    <DashboardCard className="pb-3">
      <div className="flex items-baseline gap-x-2 mt-3">
        <Typography variant="h4" className="mb-2 mt-0">
          {divisionName}
        </Typography>
      </div>
      <Typography variant="body1">
        You are viewing {divisionName}, but you are currently assigned to {assignedDivisionName}. When you&apos;re
        ready, you can return to your assigned division.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        className="mt-8"
        disabled={isLoading}
        onClick={() => handleSwitchDivisions(assignedDivisionId)}
      >
        Return to division
      </Button>
    </DashboardCard>
  )
}
