import { Button, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { useUpdatingUserClockedInHoursAndMinutes } from "../../../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { useUpdatingUserClockedInHoursAndMinutesForWeek } from "../../../hooks/useUpdatingUserClockedInHoursAndMinutesForWeek"
import { DrawerContext } from "../../Partials/Drawer/providers/DrawerProvider"
import { ClockInOut } from "../../ClockInOut"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { UserDrawer } from "../../Partials/Drawer/components/User/UserDrawer"
import { RenderIf } from "../../RenderIf"

const TimeClockWidgetQuery = graphql(`
  query TimeClockWidget {
    currentUser {
      id
      projectId
      taskId

      archived
      firstName
      isClockedIn
      isOnBreak
      lastName
      secondsClockedSinceOrgMidnight
      secondsClockedThisWeek
      currentTaskId
      latestTimeEntry {
        id
        endAt
        startAt
      }
      currentTask {
        id
        name
      }
      currentProject {
        id
        name
      }
    }
  }
`)
export const TimeClockWidget: FC = () => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const [{ data }] = useQuery({ query: TimeClockWidgetQuery })

  const { hoursClockedIn, minutesClockedIn, secondsClockedIn } = useUpdatingUserClockedInHoursAndMinutes(
    data?.currentUser,
    true
  )

  const totalWeeklyTime = useUpdatingUserClockedInHoursAndMinutesForWeek(data?.currentUser, false)

  return (
    <DashboardCard className={data?.currentUser.isClockedIn ? "bg-blue-600" : "bg-gray-800"}>
      <div className="flex items-baseline gap-x-2 mt-3">
        <Typography variant="h4" className="text-white text-2xl">
          My Time
        </Typography>
      </div>
      <div className="flex flex-col gap-y-6">
        <div className="flex md:gap-x-12 gap-x-8">
          <div className="flex flex-col gap-y-2">
            <Typography variant="subtitle1" className="text-white uppercase">
              Today
            </Typography>
            <div className="flex items-end gap-3 text-[32px] font-bold transition-colors text-white">
              {data?.currentUser?.secondsClockedSinceOrgMidnight
                ? `${hoursClockedIn}:${minutesClockedIn}:${secondsClockedIn}`
                : "-"}
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <Typography variant="subtitle1" className="text-white uppercase whitespace-nowrap">
              Week Total
            </Typography>
            <div className="flex items-end gap-3 font-semibold transition-colors text-[32px] text-white">
              {data?.currentUser?.secondsClockedThisWeek
                ? `${totalWeeklyTime.hoursClockedIn}:${totalWeeklyTime.minutesClockedIn.padStart(2, "0")}`
                : "-"}
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse justify-end gap-4">
          <div className="flex items-center space-x-2">
            {data?.currentUser?.id && (
              <Button
                onClick={() => pushDrawer(<UserDrawer userId={data.currentUser.id} tab="time-cards" />, "User")}
                className="text-white whitespace-nowrap"
              >
                View pay period
              </Button>
            )}
          </div>
          <RenderIf
            permissionsInclude={["timeEntry:clockInOut"]}
            context={{ userId: data?.currentUser.id, projectId: data?.currentUser.projectId }}
          >
            {data?.currentUser && <ClockInOut user={data.currentUser} />}
          </RenderIf>
        </div>
      </div>
    </DashboardCard>
  )
}
